/* Smartphones (portrait and landscape) ----------- */
@media only screen
  and (max-device-width : 320px) {
  .top-nav-right li a {
    padding: 0 7px;
  }
}

@media only screen
and (max-device-width : 687px) {
  .navbar {
    height: 50px;
    z-index: 0;
  }
  .input-container {
    width: 95%;
    max-width: 460px;
  }
  .form-container.registration {
    border: 0;
  }
  .login-footer ul li {
    padding: 0 5px;
  }
  .login-footer ul li a {
    font-size: 13px;
  }
  .login-form-footer {
    border-bottom: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
  }

  select {
      -webkit-appearance: none;
      -moz-appearance:    none;
      appearance:         none;
  }
  body {
    background-image: none !important;
  }


  .top-nav-right ul li{
    height: 50px;
    line-height: 50px !important;
  }

  .top-nav-right ul li.profile span{
    display: none;
  }

  .top-nav-right ul li.shop-cart a {
    font-size: 1.5em !important;
    position: relative;
  }
  .top-nav-right .shopping-cart-items-count {
    width: 17px;
    height: 17px;
    line-height: 17px;
    font-size: 10px;
  }

  .top-nav-left ul li{
    line-height: 50px !important;
  }

  .top-nav-left img {
    display: none;
  }

  .top-nav-left .title-page {
    display: none;
  }


  .top-nav-left ul li.icon {
    float: left;
    display: inline-block !important;
  }

  .top-nav-left ul li.icon a {
    font-size: 24px;
    color: #fff;
    text-decoration: none;
  }

  .sidebar {
    width: 200px !important;
    left: -202px;
    height: 100%;
    top: 0px;
    overflow-y: auto;
  }


  .open {
    opacity: 1;
    display: block;
  }

  .content-container {
    margin: 40px 0px 0px 0px !important;
    width: 100%;
  }

  .device-info-img {
    display: block !important;
    text-align: center !important;
  }

  .device-info-img img {

    max-width: 200px !important;
  }

  .device-info-img h1 {
    font-size: 30px;
    font-weight: 100;
    font-family: 'Open Sans', 'PT Sans', sans-serif;
  }

  .device-info {
    margin: 30px auto;
    width: 100% !important;
  }

  .device-info table {
    margin: 0px 10px;
    width: 100%;
  }


  .device-info table tr {
    border: 1px solid #e3e3e3;
  }

  .device-info table tr td:first-child {
    text-align: right !important;
    color: #444 !important;
    font-weight: 400 !important;
    width: 45vw;
  }

  .device-info table tr td {
    font-size: 14px;
    display: block;
    margin-bottom: 2px;
    padding: 0 0 0 10px !important;
  }
  .packages {
    width: 90% !important;
    margin: 10px auto !important;
  }

  .song-mail-form form .inp{
    display: block;
    width: 90%;
  }

  .user-profile {
    height: 100%;
    box-shadow: none;
  }

  div.user-less-info .user-photo {
    float: none;
    display: block;
    margin: 15px auto;
  }

  div.user-less-info .user-edit-link {
    margin: 10px 0px 0px 0px;
  }

  .user-less-info {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #b8b8b8;
    margin-bottom: 10px;
  }

  div.user-profile .user-contact-info {
    text-align: center;
    width: 100%;
  }


  div.user-name h1 {
    font-size: 1.5em;
  }

  div.user-right-info {
    width: 100%;
    float: none;
    display: block;
  }

  div.user-extended-info {
    display: block;
  }

  div.user-extended-info table {
    margin: 0px auto;
  }

  div.device {
    border: none;
    border-bottom: 1px solid rgba(227, 227, 227, 0.82);
    display: block;
    width: 100%;
  }
  .add-device-button {
    text-align: center;
  }

  .product-image {
    width: 100%;
  }

  .product-info .product-name h1{
    font-size: 20px;
    text-align: center;
  }
  .product-info{
    text-align: center;
  }

  .product-info .form-label {
    font-size: 12px;
  }

  .product-info .form-group select {
    margin: auto;
  }

  .product-description ul {
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 10px ;
  }

  .product-description ul li {
    width: 90%;
  }

  .total-price h3 {
    font-size: 2em;
    text-align: center;
  }

  .cart-order-button {
    text-align: center;
    margin: 50px 0px 10px 0px;
  }
  .cart-terms-link {
    text-align: center;
    margin: 20px 0px 10px 0px;
  }

  .profile-photo-modal .modal-header {
    margin: 5px;
  }

  .profile-photo-modal .info {
    margin-top: 15% !important;
  }

  .profile-photo-modal .info p {
    font-size: 14px !important;
  }

  .profile-photo-modal .support {
    line-height: 100% !important;
  }
  .profile-photo-modal .support p{
    font-size: 10px !important;
  }

  #modal .modal-content {
    padding: 5px;
    width: 90%;
  }

  .yesterday-product-modal .buy-button {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  #modal.yesterday-product-modal .info p{
    font-size: 1.2em;
    color: #009688 !important;
  }

  #modal.yesterday-product-modal .modal-product-image img{
    width: 50%;
  }

  .yesterday-product-modal .product-info {
    margin-top: 13px;
  }

  .box_yesterday_modal:not .yesterday-product-modal .product-info p{
    color: #757575;
    font-size: 12px !important;
  }
  .notification {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
  }
  .contacts-container .contact-colomn {
    margin: auto;
    display: block;
    text-align: center;
    width: 100%;
  }
  .app-detail {
    vertical-align: middle;
    display: block;
    width: 100%;
  }
  .app-links {
    margin-top: 20px;
    text-align: center;
    display: block;
  }

  .app-links ul li {
    display: inline;
    text-align: center;
    list-style: none;
  }
}

/* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
@media (min-width:641px) and (max-device-width : 800px) {
  select {
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
  }

  .content-container {
    margin: 40px 0 0 0 !important;
    width: 100%;
  }

  .navbar {
    height: 50px;
    z-index: 0;
  }

  .top-nav-right ul li{
    line-height: 50px !important;
    height: 50px !important;
  }

  .top-nav-left ul li{
    line-height: 50px !important;
    height: 50px !important;
  }

  .top-nav-right ul li.profile span{
    display: none;
  }

  .top-nav-right ul li.shop-cart a {
    font-size: 1.5em !important;
    position: relative;
  }
  .top-nav-right .shopping-cart-items-count {
    width: 17px;
    height: 17px;
    line-height: 17px;
    font-size: 10px;
  }

  .top-nav-left img {
    display: none;
  }
  .top-nav-left .title-page {
    display: none;
  }


  .top-nav-left ul li.icon {
    float: left;
    display: inline-block !important;
  }

  .top-nav-left ul li.icon a {
    font-size: 20px;
    color: #fff;
    text-decoration: none;
  }

  .sidebar {
    width: 200px !important;
    left: -202px;
    top: 0px;
    height: 100%;
    overflow: scroll;
  }

  .open {
    opacity: 1;
    display: block;
  }

  .sidebar-overlay {
    top: 50px;
  }
  div.user-profile {
    width: 90%;
    margin: 30px auto;
  }

  div.user-less-info .user-photo {
    height:140px;
    width: 140px;
  }

  div.user-name h1 {
    font-size: 1.5em;
  }

  div.user-less-info {
    width: 60%;
  }

  div.user-right-info  {
    width: 38%;
  }

  div.user-extended-info table td{
    font-size: 14px;
  }

  div.device {
    border-left: none;
    border-right: none;
    display: block;
    width: 100%;
  }

  div.device {
    border: none;
    border-bottom: 1px solid rgba(227, 227, 227, 0.82);
    display: inline-block;
    width: 100%;
  }

  .add-device-button {
    text-align: center;
  }
  .notification {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
  }

  .packages {
    width: 33% !important;
    margin: 10px auto !important;
  }

  #modal .modal-content {
    overflow: auto;
    width: 50%;
  }


}
/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width:801px) and (max-device-width : 1223px) {
  select {
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
  }

  .content-container {
    margin: 40px 0 0 0 !important;
    width: 100%;
  }

  .navbar {
    height: 50px;
    z-index: 0;
  }

  .top-nav-right ul li{
    line-height: 50px !important;
    height: 50px !important;
  }

  .top-nav-left ul li{
    line-height: 50px !important;
    height: 50px !important;
  }

  .top-nav-right ul li.profile span{
    display: none;
  }

  .top-nav-right ul li.shop-cart a {
    font-size: 1.5em !important;
    position: relative;
  }
  .top-nav-right .shopping-cart-items-count {
    width: 17px;
    height: 17px;
    line-height: 17px;
    font-size: 10px;
  }

  .top-nav-left img {
    display: none;
  }
  .top-nav-left .title-page {
    display: none;
  }


  .top-nav-left ul li.icon {
    float: left;
    display: inline-block !important;
  }

  .top-nav-left ul li.icon a {
    font-size: 20px;
    color: #fff;
    text-decoration: none;
  }

  .sidebar {
    width: 200px !important;
    left: -202px;
    height: 100%;
    top: 0px;
    overflow: auto;
  }

  .open {
    opacity: 1;
    display: block;
  }


  .sidebar-overlay {
    top: 50px;
  }
  .content-container {
    padding: 50px 0px 0px 0px;
    margin: 40px 0px 0px 0px !important;
  }

  div.user-profile {
    width: 90%;
    margin: 30px auto;
  }

  div.device {
    border: none;
    display: inline-block;
    width: 33%;
  }

  .add-device-button {
    text-align: center;
  }
  .notification {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
  }

  #modal .modal-content {
      width: 50%;
  }
}

/* Desktops and laptops ----------- */
@media only screen
and (min-width : 1224px) {
  /**/
}

/* Large screens ----------- */
@media only screen
and (min-width : 1824px) {
/* Styles */
}

@media only screen and (max-device-width: 420px) {
  .device-info table tr td:first-child {
    width: unset;
    text-align: left !important;
  }
  .device-info table tr td {
    padding: 0 0 0 10px !important;
    display: block !important;
  }
  .device-info table tr td:last-child {
    font-weight: bold;
  }
}
