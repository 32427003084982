.youtube-popup {
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index:99999;
}

.youtube-popup-content {
	margin-top:100px;
	width:704px;
	height: 396px;
	margin-left: calc(250px + calc(50% - 524px));
	margin-right:auto;
	position:relative;
	padding: 60px 22px 32px 22px;
	background: #FFFFFF;
	border-radius: 14px;
}

.youtube-popup-close {
	position: absolute;
	top: 8px;
	right: 22px;
	color: #FFF;
	font-size: 25px;
	width: 20px;
	height: 20px;
	cursor: pointer;
}

.youtube-iframe {
	width:100%;
	height:396px;
}

@media only screen and (max-width: 1223px) {
	.youtube-popup-content {
		margin-left: auto;
		margin-right: auto;
		border-radius: 8px;
		padding: 35px 5px 5px 5px;
	}

	.youtube-popup-close {
		right: 5px;
		top: 5px;
	}
}

@media only screen and (max-width:767px) {
	.youtube-iframe {
		width:100%;
		height:100%;
	}
	.youtube-popup-content {
		width: 94%;
		height: 53vw;
	}
}

.youtube-dark-theme{
	background: rgba(0,0,0, 0.85);
}

.youtube-dark-theme .youtube-popup-content {
	-webkit-box-shadow: 0 0 8px 0 rgba(255,255,255,0.4);
	-moz-box-shadow: 0 0 8px 0 rgba(255,255,255,0.4);
	box-shadow: 0 0 8px 0 rgba(255,255,255,0.4);
}

.youtube-dark-theme .youtube-popup-close {
	background: url('../../assets/images/Close.png') no-repeat;
}

.youtube-light-theme {
	background: rgba(255,255,255, 0.85);
}

.youtube-light-theme .youtube-popup-content {
	-webkit-box-shadow: 0 0 8px 0 rgba(0,0,0,0.4);
	-moz-box-shadow: 0 0 8px 0 rgba(0,0,0,0.4);
	box-shadow: 0 0 8px 0 rgba(0,0,0,0.4);
}

.youtube-light-theme .youtube-popup-close {
	background: url('../../assets/images/Close.png') no-repeat;
}


