@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@700&display=swap');


@keyframes shake {
    0% {
        transform: translateX(0);
    }

    20% {
        transform: translateX(-10px);
    }

    40% {
        transform: translateX(10px);
    }

    60% {
        transform: translateX(-10px);
    }

    80% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0);
    }
}
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-ms-keyframes slideDown {
    0%, 100% { -webkit-transform: translateY(100%); }
    10%, 90% { -webkit-transform: translateY(0); }
}

@-o-keyframes slideDown {
    0%, 100% { -webkit-transform: translateY(100%); }
    10%, 90% { -webkit-transform: translateY(0); }
}

@-webkit-keyframes slideDown {
    0%, 100% { -webkit-transform: translateY(-110%); }
    10%, 90% { -webkit-transform: translateY(0); }
}
@-moz-keyframes slideDown {
    0%, 100% { -moz-transform: translateY(-110%); }
    10%, 90% { -moz-transform: translateY(0); }
}
@font-face {
    font-family: 'icomoon';
    src:url('../fonts/icomoon.eot?a0vqiu');
    src:url('../fonts/icomoon.eot?#iefixa0vqiu') format('embedded-opentype'),
        url('../fonts/icomoon.woff?a0vqiu') format('woff'),
        url('../fonts/icomoon.ttf?a0vqiu') format('truetype'),
        url('../fonts/icomoon.svg?a0vqiu#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.icon-clip:before {
    content: "\e604";
}
.icon-videoke:before {
    content: "\e605";
}
.icon-back:before {
    content: "\e600";
}
.icon-cdg:before {
    content: "\e601";
}
.icon-lite:before {
    content: "\e602";
}
.icon-pro:before {
    content: "\e603";
}
.icon-livefx:before {
    content: "\e606";
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

html {
    height: 100%;
}

body {
    position: relative;
    left: 0;
    color: #444;
    font-family: 'Open Sans', 'PT Sans', sans-serif;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition-property: left;
    overflow-x: clip;
}
ul {
    padding: 0;
    margin:0;
    white-space:nowrap;
}
a {
    color: #00afed;
    text-decoration: none;
}
a:hover {
    color: #ffd180;
    cursor: pointer;
}
input {
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    outline: none;
    margin: 5px auto;
    border: 1px solid #DDDDDD;
}
select {
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    /*-webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;*/
    -ms-box-sizing:content-box;
    -moz-box-sizing:content-box;
    -webkit-box-sizing:content-box;
    box-sizing:content-box;
    outline: none;
    margin: auto;
    border: 1px solid #DDDDDD;
}
input:focus, textarea:focus {
    border: 1px solid #ffd180;
    background-color: #fff;
}

h1 {
    font-size: 36px;
    line-height: 40px;
}

h2 {
    font-size: 30px;
    line-height: 40px;
}

h3 {
    font-size: 24px;
    line-height: 40px;
}

h4 {
    font-size: 18px;
    line-height: 20px;
}

/* Won't be used here */

h5 {
    font-size: 14px;
    line-height: 20px;
}

h6 {
    font-size: 12px;
    line-height: 20px;
}

 .box_yesterday_modal, .box_content {
     font-family: PT Sans, sans-serif;
 }

.strong {
    font-weight:bold !important;
}
.notification {
    position: fixed;
    z-index: 101;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    line-height: 60px;
    color: #fff;
    background: #2ECC71;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    -webkit-box-shadow: 0 0 5px black;
    -moz-box-shadow:    0 0 5px black;
    box-shadow:         0 0 5px black;
    -webkit-transform: translateY(-110%);
    -webkit-animation: slideDown 4.0s .0s 1 ease forwards;
    -moz-transform: translateY(-110%);
    -moz-animation: slideDown 4.0s .0s 1 ease forwards;
}

.notification-error {
    background-color: #ff0000 !important;
}

.notification-warning {
    background-color: #ff0000 !important;
}

#serial_number {
    text-transform: uppercase;
}
#serial_number::-webkit-input-placeholder {
    text-transform: none;
}
#serial_number::-moz-placeholder {
    text-transform: none;
}
#hwid {
    text-transform: uppercase;
}
.body {

}
.main-container {
    left: 0;
    margin: 0 5px;
    position: relative;
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    transition-property: all;
    will-change: transform;
}

.content-container {
    padding-top: 60px;
    margin: 60px 50px 200px 250px;
}

.top-header {
    margin: 0;
    padding: 0;
    overflow: hidden;

}

.navbar {
    height: 60px;
    width: 100%;
    background-color: #ffa200;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition-property: all;
}
.navbar.fixed-navbar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
}

.top-nav-left {
    height: 100%;
    float: left;
    padding-left: 10px;
}

.top-nav-left ul {
    height: 100%;
}

.top-nav-left ul li {
    display: inline-block;
    height: 60px;
    line-height: 60px;
    vertical-align: middle;
    color: #fff;
    font-size: 1.4em;
    font-weight: 100;
    font-family: 'Open Sans', 'PT Sans', sans-serif;
}

.top-nav-left .line-separetor {
    padding-right: 10px;
}

.top-nav-left .logo{

}

.header-message {
    font-size: 2em;
    font-weight:200;
}
.top-nav-left .logo img{
    width: 150px;
    padding: 10px 15px;
}

.top-nav-right {
    list-style-type: none;
}

.top-nav-right ul li {
    display: inline-block;
    position: relative;
    height: 60px;
    line-height: 60px;
    vertical-align: middle;
    color: #fff;
    font-size: 1em;
    font-family: 'Open Sans', 'PT Sans', sans-serif;
}

.top-nav-right ul li:hover {
    background-color: rgb(232, 147, 0);
}

.top-nav-right .lang a {
    text-transform: uppercase;
}

.top-nav-right ul li.shop-cart a {
    font-size: 2em;
    position: relative;
}

.top-nav-right .submenu {
    visibility:hidden;
    z-index: 2;
    float: none;
    position: fixed;
    background-color: #ffa200;
}

.visibility {
    visibility:visible;
}

.top-nav-right .submenu li{
    display: block;
    color: #000;
}

.top-nav-right .lang:hover > ul{
    visibility:visible;
}
.top-nav-right .lang:active > ul{
    visibility:visible;
}
.top-nav-right .lang:focus > ul{
    visibility:visible;
}
a.order-delete{
    color:#b8b8b8;
    padding-left: 10px;
}
a.order-delete:hover{
        color: #ffd180;
}

.top-nav-right  {float: right;}

.top-nav-right li a {
    display: inline-block;
    color: #f2f2f2;
    text-align: center;
    padding: 0 16px;
    text-decoration: none;
    transition: 0.3s;
    font-size: 17px;
}

.top-nav-right .profile img{
    height: 30px;
    vertical-align: middle;
    border-radius: 50%;
}

.shopping-cart-items-count {
    position: absolute;
    background: #00afed;
    width: 20px;
    height: 20px;
    color: #ffffff;
    top: 9px;
    left: 33px;
    line-height: 20px;
    text-align: center;
    font-family: 'Open Sans', 'PT Sans', sans-serif;
    font-weight: bold;
    font-size: 12px;
    z-index: 9;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%;
}

.top-nav-left ul li.icon {
    display: none;
}

.sidebar {
    width: 200px;
    height: 100%;
    background-color: #fff;
    border-right: 1px solid #e3e3e3;
    z-index: 3;
    position: fixed;
    top: 60px;
    overflow: auto;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition-property: all;
}
.sidebar-footer {
    bottom: 0;
    clear: both;
    display: block;
    padding: 15px 0 15px;
    position: fixed;
    width: 200px;
    background: #10bc10;
    z-index: 999;
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    #text-align: center;
}



.sidebar-footer p a{
    text-decoration: none;
    color: #ffffff;
}
.sidebar-footer p {
    font-size: 12px;
    text-align: left;
    padding-left: 10px;
    color: #ffffff;
}
.sidebar ul {
    list-style: none;
    margin: 0 auto;
}

.sidebar ul li {
    display: block;
    text-decoration: none;
    color: inherit;
    font-family: 'Open Sans', 'PT Sans', sans-serif;
    font-size: 1em;
    border-bottom: 1px dotted #e3e3e3
}

.sidebar .logo {
    text-align: center;
}

.sidebar .logo img{
    width: 150px;
    padding: 10px;
}

.sidebar ul li i {
    padding-right: 10%;
}

.sidebar ul li a{
    color: #000;
    padding: 10%;
    text-decoration: none;
    display: block;
    color: #444;
    font-family: 'Open Sans', 'PT Sans', sans-serif;

}

.sidebar ul li a:hover {
    background: #00afed;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
}

.sidebar-open {
    -webkit-transform: translateX(200px);
    transform: translateX(200px);
    transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
    will-change: transform;
}


.active-toggle {
    -webkit-transform: translateX(200px);
    transform: translateX(200px);
    transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
    will-change: transform;

}

.deactive-toggle {
    -webkit-transform: none;
    transform: none;
}

.sidebar-overlay {
    /*background: rgba(0,0,0,.5);*/
    position: fixed;
    left: 0;
    top: 50px;
    right: 0;
    bottom: 0;
    opacity: 0;
    display: none;
    z-index: 3;
    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -ms-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    transition-property: all;
}

.sidebar-notification {
    color: #446CB3;
    margin-left: 10px;
    background: #c6e2ff;
    padding: 3px 10px;
    border-radius: 5px;
    font-size: 12px;
}

.no-scroll {
    overflow: hidden;
    /*position:fixed;*/
}

.horizontal-separator {
    border-top: 1px dotted #e3e3e3;
    margin-top: 75px;
    clear: both;
}

.orange {
    color: #ff9100;
}

.blue {
    color: #00afed;
}

.green {
    color: #87D37C;
}

.section-title {
    text-align: center;
    padding: 10px;
}

.section-title h1 {
    font-size: 1.9em;
    font-weight: 100;
    font-family: 'Open Sans', 'PT Sans', sans-serif;
}

.table {
    border-collapse: collapse;
    margin: 4px auto;
    width: 100%;
}

.table tr td {
    border: 1px solid #e6e6e6;
    padding: 15px;
    white-space: nowrap;
}

.table-adv tr td, .table-adv thead th {
   vertical-align: middle;
    align-content: center;
    text-align: center;
}


.table-responsive {
    display: block;
    width: 100%;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    min-height: .01%;
    overflow-x: auto;
}

.table-adv {
    width: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    min-height: .01%;
    overflow-x: auto;
}




.device-info-img {
    #text-align: center;
    display: inline-block;
    vertical-align: top;
}

.device-info-img img {
    max-width: 300px;
}

.my-devices ul {
    list-style: none;
}

.my-devices ul li{
    display: inline-block;
    width: 33%;
}

.my-devices .device-info ul li{
    display: block;
}

.my-devices .device-info-img {
    display: block;
}

.my-devices .device-info-img img{
    width: 300px;
}

.device-info {
    margin-left: 40px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
}
.device-info .label {
    font-size: 13px;
    color: #a6a6a6;
}
.device-info table {
    font-size:13px;
    margin: 0 auto;
}

.device-info table tr {
    border: 1px solid #e3e3e3;
}

.device-info table tr td:first-child {
    font-size:12px;
    text-align: right;
    font-weight: 400;
}

.device-info table tr td:last-child {
    font-weight: 400;
}

.device-info table tr td {
    padding: 0 0 0 10px;
    text-align: left;
    min-width: 150px;
}

.pricing-table {
    width: 50%;
    text-align: center;
    margin: 20px auto;
}

.packages {
    display: inline-block;
    margin: 0 auto;
    min-width: 375px;
    border: 1px solid #e6e6e6;
    border-top: 2px solid #ff9100;
    height: 100%;
    vertical-align: top;
}

.packages.yesterday {
    border-top: 10px solid #EC4958 !important;
}

.packages.annual {
    border-top: 10px solid #23BAF4 !important;
}
.packages.annual.club {
    border-top: 10px solid #9856CA !important;
}

.packages.annual.personal_package {
    border-top: 10px solid #fce024 !important;
    border: 2px solid #fce024;
}
.packages .package-list {
    margin: 20px 0;
}

.packages p {
    padding: 15px;
    font-size: 24px;
    text-align: center;
    margin: auto;
    width: 90%;
/*    text-transform: uppercase;
    border-bottom: 1px solid #e6e6e6;*/
}

.personal_package p {
    max-height: 32px !important;
    padding: 15px 0 !important;
}

.packages .package-button {
    margin: 60px 0 40px 0;
}

.packages .package-button a{
    font-size: 24px;
    font-weight: 100;
    padding: 10px 40px;
}

.packages .product-image {
    width: 100%;
    margin: 10px auto;
}
.packages button {
    margin: 30% auto 10%;
}

.packages ul {
    list-style: none;
    padding: 0 10px 0 10px;

}

.packages ul li {
    text-align: left;
    padding: 10px;
    font-size: 14px;
}

.packages ul li:nth-child(odd) {
    /*background-color: rgba(23, 61, 80, 0.06);*/
}

.no-discount {
    margin-bottom: 55px !important;
}

.package-price {
    /*background-color: #E4F1FE;*/
    vertical-align: middle;
    color: #444444;
}

.package-price .package-value{
    font-size: 3em;
}

.package-price .package-currency{
    color: #8b8b8b;
}

.updates-table {
    margin: 30px auto;
}

.tabs-menu {
    list-style: none;
}

.tabs-menu li a.current {
    border-bottom: 4px solid #ffa200;
}

.tabs-menu li a:hover {
    color: #ffa200;
    border-bottom: 4px solid #ffa200;
}

.tabs-menu li {
    display: inline;
    text-align: center;
}

.tabs-menu li a {
    line-height: 28px;
    background: #fff;
    text-decoration: none;
    color: #8b8b8b;
    font-weight: bold;
    font-family: 'Open Sans', 'PT Sans', sans-serif;
    padding: 3px 2%;
}

.tabs-content table {
    border-collapse: collapse;
    margin: 4px auto;
    width: 100%;
}


.tabs-content table tr td:nth-child(5) {
    width: 15%;
}

.update-list-icon{
    color: #000;
    font-size: 16px;
    padding: 10px;
}

.tabs-content {
    display: none;
}
.container {
    margin: 60px auto 0;
    /*min-width: 960px;*/
}
.form-logo {
    margin-top: 40px auto;
}
.form-logo img {
    width: 260px;
    display: block;
    margin: 20px auto;
}
.form-header {
    font-size: 24px;
    font-weight: 200;
    margin: 15px;
    text-align: center;
    font-family: 'Open Sans', 'PT Sans', sans-serif;
}
.form-container {
    display: block;
    margin: auto;
    border: 1px solid #e0e0e0;
}
.form-group {
    margin: 5px;
}
.form-input-mess {
    margin: 5px 15px 10px 15px;
    font-size: 14px;
}
.form-label {
    margin: 20px 15px 5px 15px;
    color: #838383;
    font-weight: 600;
    font-size: 14px;
}
.error {
    color: red;
}
.success {
    color: lime;
}
.input-container {
    width: 460px;
    margin: 0 auto;
}
.inp {
    display: block;
    width: 90%;
    height: 45px;
    padding: 0 0 0 13px;
    font-size: 15px;
    border: solid 1px #eeeeee;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 2px;
    background-color: rgba(247, 247, 247, 0.5);
    font-family: 'Open Sans', 'PT Sans', sans-serif;
}
.inp-select {

}

.btn {
    padding: 8px 15px;
    cursor: pointer;
    color: #fff;
    font-size: 17px;
    font-weight: bold;
    border: 2px solid #3DA2E6;
    text-align: center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    display: inline;
    background-color: #3DA2E6;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
}

.btn:hover {
    background-color: #F39D2A;
    border: 2px solid #F39D2A;
    color: white;
}

.btn-blue {
    background-color: #00afed;
}

.btn-blue-empty {
    background-color: #fff;
    color: #00afed;
}

.btn-blue-empty:hover {
    background-color: #00afed;
    color: #fff;
}

.btn-orange {
    background-color: #ffa200;
    border: 1px solid #ffa200;
}

.btn-green:hover {
    color: #ffa200;
    /*background-color: #00a500;*/
    /*border: 2px solid #00a500;*/
}

.btn-green {
    color: #00a500;
    background-color: #fff;
    border: 0;
    padding: 0;
    font-size: 16px;
    /*border: 2px solid #00a500;*/
}

.btn-download {
    padding: 5px;
    margin: 5px;
    font-size: 12px;
    text-decoration: none;
}


.btn-blue:hover {
    background-color: #31bdee;
    color: #fff;
}
.btn.login {
    padding: 10px 10%;
    margin: 10px;
}
.center {
    text-align: center;
}
.left {
    text-align: left;
}
.right {
    text-align: right;
}

.row {
    margin: 10px 0;
}

.open {
    display: none;
}

.hidden {
    display: none;
}
.login-form-footer {
    height: 51px;
    line-height: 51px;
    border-bottom: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    background-color: #f2f2f2;
}
.login-form-footer a {
    font-size: 1.1em;
    color: #00afed;
    font-family: 'Open Sans', 'PT Sans', sans-serif;
}
.policy-footer {
    margin-top: 40px;
}
.policy-footer a {
    display: block;
    text-align: center;
    margin-top: 15px;
    color: #8b8b8b;
    text-decoration: none;
    font-size: 14px;
    font-family: Open Sans,PT Sans,sans-serif;
}
.login-footer {
    padding-top: 20px;
    padding-bottom: 10px;
    width: 100%;
    clear: both;
    text-align: center;
}
.login-footer ul {
    width: 100%;
    padding: 0 ;
}
.login-footer ul li {
    padding: 0 20px 0 10px;
    display: inline;
    text-decoration: none;
}
.login-footer ul li a {
    color: #8b8b8b;
    text-decoration: none;
    font-size: 14px;
    font-family: 'Open Sans', 'PT Sans', sans-serif;
}
.login-footer ul span.separator {
    padding: 0 20px 0 20px;
    color: #eeeeee;
}
.registration-btn {
    margin: 15px auto;
}
.langs-footer {
    margin: 20px auto;
    text-align: center;
}
.langs-footer ul li{
    padding: 5px;
    display: inline;
}
.langs-footer a {
    text-decoration: none;
}
.langs-footer img{
    width: 20px;
}
.input-error {
    border: 1px solid red;
}
.input-success {
    border: 1px solid lime;
}
.system-info-help-link {
    margin: 0;
    text-align: center;
}
.system-info-help-link a {
    text-decoration: none;
}
.disabled {
/*    color: #fff;
    background: none repeat scroll 0 0 #d8d8d8;*/
    border: none;
    cursor: default !important;
}
.disabled:hover {
    border: none;
    background: none repeat scroll 0 0 ;
}

.song-mail-form {
    margin: 0 auto;
    text-align: center;
    color: #444;
}

.song-mail-form h2{
    margin: 10px;
    font-size: 1.5em;
}

.song-mail-form .form-group{
    margin: 20px;
}

.song-mail-form form{
    margin: 20px;
}

.song-mail-form form .inp{
    display: inline;
    width: 33%;
}

.song-mail-form .header-note{
    font-size: 0.7em;
}

.news-info {
    width: 90%;
    margin: 40px auto;
}

.news-info .news-content img {
    width: 100%;
    height: 100%;
}

.news-info .news-date {
    color: #a6a6a6;
    font-size: 0.8em;
    padding: 0;
    font-family: 'Open Sans', 'PT Sans', sans-serif;
}

.news-info .news-title {
    font-size: 1.4em;
    color: #000;
    padding-bottom: 18px;
    font-family: 'Open Sans', 'PT Sans', sans-serif;
}

.news-info .news-content {
    color: #000000;
    font-size: 1em;
    padding-bottom: 11px;
    font-family: 'Open Sans', 'PT Sans', sans-serif;
}

.user-profile {
    display: flex;
    width: 80%;
    margin: 0 auto;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11),0 2px 10px 0 rgba(0, 0, 0, 0.07);
}

.user-less-info {
    width: 65%;
    display: inline-block;
    border-right: 1px solid #e3e3e3;
}

.user-profile .user-photo .photo-content {
    color: #fff;
    opacity: 0;
    position: absolute;
    top:0;
    width: 100%;
    vertical-align: middle;
    line-height: 150px;
    text-align: center;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
}


.photo-content a {
    color: #fff;
    font-weight: 400;
}

.photo-content a:hover {
    color: #79ebff;
}

.user-profile .user-photo:hover .photo-content  {
    background-color: rgba(0, 0, 0, 0.74);
    opacity: 1;
    -moz-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
}

.user-profile .user-photo {
    position: relative;
    display: inline-block;
    opacity: 0.9;
    border-radius: 50%;
    overflow: hidden;
    width: 150px;
    height: 150px;
    margin: 25px 30px;
    float: left;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
}

.user-photo-icon {
    font-size: 190px;
    color: #b8b8b8;
}

form#photo-form input {
    display: none;
}

a.active {
    background: #00afed;
    text-decoration: none;
    color: #fff !important;
}
.user-profile .user-photo img {
    display: block;
    width: 100%;
}

.user-profile .user-contact-info {
    /*display: inline-block;*/
    margin: 25px 25px;
}

.user-name h1 {
    font-family: 'Open Sans', 'PT Sans', sans-serif;
    font-size: 1.7em;
    color: #444;
}

.user-location h3 {
    margin: 5px 0;
    color: #868686;
}

.user-right-info {
    position: relative;
    margin: 0 auto;
    height: 100%;
    display: table;
    float: right;
    width: 32%;
}

.user-extended-info {
    width: 100%;
    display: table-cell;
    vertical-align: middle;
}

.user-extended-info table{
}

.user-extended-info table td{
    padding: 5px 5px;
}

.user-extended-info td:nth-child(1){
    font-weight: 600;
    color: #444;
}

.user-extended-info td:nth-child(2) {
    color: #868686;
}

.user-edit-link {
    /*margin: 60px 0 0 0;*/
    font-size: 14px;
}

.user-edit-link a{
    text-decoration: none;
}

.vertical-seperator {
    border-left: 1px solid;
    height: 80%;
}

.device {
    position: relative;
    margin-top: 5px;
    min-height: 405px;
    min-width: 320px;
    display: inline-block;
    vertical-align: top;
    border: 1px solid rgba(227, 227, 227, 0.82);
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
}

.device .devices-info-img {
    margin-top: 20px;
    display: block;
}
.device .devices-info-img img {
    display: block;
    margin: 0 auto;
    height: 100px;
}
.device .devices-name {
    margin-top: 10px;
    display: block;
}
.device .devices-name p {
    text-align: center;
    font-size: 1.5em;
    color: #444;
}
.device .devices-name p a{
    text-decoration: none;
}
.device .devices-info  {
    margin: 30px auto;
    font-size: 14px;
}
.device .devices-info table  {
    text-align: left;
    margin: auto;
}
.device .devices-info table td  {
    padding: 5px 10px;
}
.device .devices-info table tr td:nth-child(1) {
    font-weight: 600;
    color: #444;
}
.device .devices-info table tr td:nth-child(2) {
    color: #868686;
}
.device .more-link {
    position: absolute;
    margin-bottom: 14px;
    margin-top: auto;
    text-align: center;
    min-width: 320px;
    width: 100%;
    bottom: 7px;
}
.add-device-button {
    margin: 30px 0;
}
.add-device .header-info {
    text-align: center;
}
.add-device .header-info h2{
    font-size: 24px;
}

.add-device .add-button {
    text-align: center;
}

.update-list-icon:hover {
    color: #ffa200;
    cursor: pointer;
}

#ulist .table-icons {
    font-size: 1.5em;
}

.updatelist-container {
    width: 90%;
    margin: auto;
}

.cart-icon-rotate {
    transform: rotateY(360deg);
}

.shake {
    animation: shake .5s ease-in-out;
}
.cart-container {
    width:90%;
    margin: 0 auto;
}

.cart-container table tr td {
    padding: 10px 5px;
    vertical-align: middle;
}

.cart-container table tr td:nth-child(0) {
    text-align: center;
}

.cart-container table tr td:nth-child(3){
    text-align: center;
}

.cart-container table tr td:nth-child(4){
    text-align: center;
}

.cart-container table tr td:nth-child(4) a{
    color: red;
}

.cart-container table tr td:nth-child(4) a:hover{
    color: #ffa200;
}

.cart-container table tr td ul {
    list-style: none;
}

.cart-container table tr td ul li:nth-child(2) {
    color: #b8b8b8;
}

.total-price {
    margin: 10px auto;
}

.total-price h3 {
    text-align: right;
    font-size: 2.5em;
    font-weight: 100;
    font-family: 'Open Sans', 'PT Sans', sans-serif;
}

.cart-order-button {
    text-align: right;
    margin: 15px auto;
}

.cart-order-button .btn {
    text-decoration: none;
    padding: 10px 30px;
}



.cart-terms-link {
    text-align: right;
}

.order-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.order-table tr td {
    text-align: center;
    padding: 10px;
    border: none;
}

.edit-profile-form {
    max-width: 460px;
    margin: auto;
}

.edit-profile-form .user-photo{
    border-radius: 50%;
    margin: auto;
    overflow: hidden;
    width: 150px;
    height: 150px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
}

.edit-profile-form .user-photo img{
    display: block;
    width: 100%;
}

.payment-logos {
    text-align: right;
    color: #b8b8b8;
    padding: 10px;
}

.payment-logos .logos {
    display: inline-block;
}

.payment-logos .payment-security {
    display: inline-block;
}

.icon-lock {
    position: absolute;
    top: 50%;
    left: 0;
    margin: -16px 0 0 0;
    width: 22px;
    height: 29px;
    background-image: url('../images/icon-lock.png');
    background-size: contain;
}

.orders-container table tr td{
    text-align: left;
}

.orders-container table tr td:nth-child(4) {
    /*text-align: right;*/
}

.orders-container table tr td ul {
    list-style: none;
}

.orders-container table tr td ul li:nth-child(2) {
    color: #b8b8b8;
}

.contacts-container .contact-colomn {
    vertical-align: top;
    display: inline-block;
    width: 40%;
}

.contacts-container .contact-colomn p{
    margin-bottom: 10px;
}

.contacts-container .contact-colomn strong{
    font-weight: bold;
}

.contacts-container .contact-colomn-header {
    margin: 15px 0;
}

.contacts-container .contact-colomn-header .header-colomn {
    display: block;
}

.contacts-container .contact-colomn-header h4 {
    font-size: 32px;
    line-height: 34px;
}

.orders-container {
    margin: 40px auto;
}

.order-table {
    border-top: 1px solid #e3e3e3;
}

.payment-status {
    text-align: right;
}

.payment-status h1 {
    font-family: 'Open Sans', 'PT Sans', sans-serif;;
}

.row-title {
    width: 960px;
    margin: 20px auto;
    text-align: center;
}
.row-title p{
    font-size: 24px;
}
.row-title-img {
    margin-top: 20px;
}
.row-title-img img {
    padding-left: 10px;
}

.help-container {
    max-width: 960px;
    margin: 0 auto;
}

.help-row-title {
    width: 350px;
    clear:both;
    margin-bottom: 5px;
    margin-top: 5px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
}
.help-row-title p {
    color: #fff;
    padding-left: 10px;
}
.help-row {
    height: 200px;
    border-bottom: 1px solid #e5e5e5;
}
.help-row1 {
    width: 40%;
    float: left;
    margin: 0 auto;
}
.help-row1 img {
    width: 70%;
    display: block;
    padding-top: 10px;
    padding-left: 40px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
}
.help-row2 {
    width: 60%;
    float: right;
    margin-top: 10px;
}
.help-row2 ol li{
    font-size:16px;
    color: #454545;
}
.help-row li a {
    text-decoration: none;
    color: #009FF2;
}
.help-row li a:hover {
    color: #ffb600;
}


#modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 4; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;

}
#modal .modal-content {
    text-align: center;
    padding: 30px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    -webkit-animation: fadein .5s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein .5s; /* Firefox < 16 */
    -ms-animation: fadein .5s; /* Internet Explorer */
    -o-animation: fadein .5s; /* Opera < 12.1 */
    animation: fadein .5s;
}

#modal .info {
    text-align: center;
    margin-top: 10%;
}
#modal .info p {
    font-size: 1.4em;
    color: #444;
}

#modal .support {
    margin-top: 10%;
    height: 60px;
    line-height: 60px;
    border-top: 1px solid #e3e3e3;
    text-align: center;
}
#modal .image-preview {
    margin: 30px;
    text-align: center;
}

#modal .image-preview img {
    max-width: 30%;
}

#modal .support p {
    color: #5d5d5d;
    font-size: 14px;
}
#modal .upload-button {
    margin: 30px;
    text-align: center;
}
.modal-header {
    position: absolute;
    font-size: 20px;
    right: 20px;
    top: 20px;
}

#progressbar {
    display: none;
    -webkit-appearance: none;
    appearance: none;
    width: 300px;
    height: 20px;
    background-color: whiteSmoke;
}

.product-container {
    width: 100%;
    font-family: 'Open Sans', 'PT Sans', sans-serif;
}
.product-image {
    text-align: center;
    vertical-align: middle;
    display: block;
    float: left;
    width: 30%;
}
.product-image img {
    width: 200px;
}
.product-info {
    display: inline-block;
}
.product-info .form-label{
    margin: 20px 15px 5px 0;
}

.product-info .form-group select{
    margin: 0;
}

.product-info h1 {
    font-size: 36px;
    font-weight: 100;
}

.product-info h3 {
    font-size: 24px;
    color: #868686;
}

.product-info p {
    font-size: 14px;
    color: #868686;
}

.product-description {
    margin-top: 20px;
}

.product-description h4 {
    font-weight: 600;
    color: #4c4c4c;
}

.product-description ul {
    margin-top: 10px;
}

.product-description ul li {
    list-style: none;
    padding: 5px 0 5px 0;
    color: #868686;
}

.product-description ul li i{
    float: right;
}

#modal.yesterday-product-modal {
    display: block;
    font-family: 'Open Sans', 'PT Sans', sans-serif !important;
}
.yesterday-product-modal .modal-product-image {
    margin-top: 4%;
}

.yesterday-product-modal .info {
    margin-top: 41px !important;
}
#modal.yesterday-product-modal .info p{
    font-size: 30px;
    color: #009688 !important;
}

.yesterday-product-modal .product-info {
    #margin-top: 33px;
    margin-bottom: 33px;
    font-size: 16px !important;
}
.yesterday-product-modal .product-info p{
    color: #757575;
    font-size: 18px !important;
}

.yesterday-product-modal .buy-button {
    margin-top: 45px;
}

.addon{
    width: 33.33%;
    display: block;
    float: left;
    text-align: center;
    min-height: 290px;
}
.addon h3 {
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.addons{
    clear: both;
}

.catalogs{
    clear: both;
}

.pagination {
    display: inline-block;
    list-style: none;
}

.pagination li{
    float: left;
}

.pagination li a, .pagination li span {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
}

.pagination li.active {
    background-color: #ffa200;
    color: gray;
}

.pagination a:hover:not(.active) {
    background-color: #ddd;
}

.text-center{
    text-align: center;
}

.remove-device{
    margin-top: 30px;
    font-size: 16px;
}

.app-row {
    padding: 10px 0 10px 0;
    margin: 20px 0 20px 0;
    /*border-top: 1px solid #e3e3e3;*/
    border-bottom: 1px solid #e3e3e3;

}

.app-links {
    display: table-cell;
    vertical-align: middle;
}
.app-description {
    font-size: 14px;
}
.app-detail {
    width:48%;
    vertical-align: middle;
    display: table-cell;
}
.app-title {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 5px;
}
.app-row .app-image {
    vertical-align: middle;
    text-align: center;
    width: 20%;
    display: table-cell;
    padding: 0 10px 0 10px;
}
.app-row .app-image img{
    border-radius: 8px;
    width:100px;
}

/*.app-links .google-play a img {*/
    /*width: 173px;*/
/*}*/

.app-links a img {
    width: 135px;
}

.app-links ul li {
    text-align: center;
    list-style:none;
}

.banner-preview {
    width: 100%;
    text-align: center;
}

.banner-preview img {
    max-width: 300px;
    border: 1px solid gray;
}

@media(max-width:992px){
    .user-profile {
        flex-direction: column;
        width: 100%;
    }

    .user-less-info {
        width: 100%;
        display: inline-block;
        border-right: unset;
        border-bottom: 1px solid #e3e3e3;
    }

    .user-right-info {
        width: unset;
        margin-left: 7px;
    }

    .user-profile .user-contact-info {
        margin: 25px 15px;
    }
}

@media(max-width:768px){
    .addon{
        width: 100%;
    }

    .user-less-info {
        display: flex;
        flex-direction: column;
    }

    .user-profile .user-photo {
        margin: 25px auto;
        float: unset;
    }
}

@media(min-width:768px) and (max-width:1024px){
    .addon{
        width: 50%;
    }
}

@media(max-width: 441px) {
    #balance {
        display: none;
    }
}

.alert-danger {
    margin: 5px 0 0 33px;
    color: red;
    font-size: 12px;
}

/*Новый дизайн продуктов для эвобокса*/

.box_yesterday_modal .modal-content {
    height: 631px;
    width: 438px;
}

.pricing-table {
    width: 90%;
}
.pricing-table h2 {
    padding: 24px 0 28px 0;
}
.box-products {
    font-family: "PT Sans", sans-serif !important;
    display: flex;
    margin: 5px auto;
    width: 100% !important;
    min-width: 620px;
    max-width: 748px;
    min-height: 125px;
    max-height: 155px;
    border: 1px solid #e6e6e6;
    background: #EEEEEE;
    border-radius: 4px;
    vertical-align: middle;
    text-align: left;
}
.amount {
    min-width: 130px;
    width: 155px;
    height: 50px;
}
.pricing-table h4 {
    display: flex;
    margin: 29px auto 20px auto;
    width: 100%;
    min-width: 240px;
    max-width: 748px;
    font-family: PT Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: #8B8B8B;

}
.box-package {
    margin-top: 20px;
    vertical-align: middle;
    width: 58%;
}
.box-package > .title {
    margin-left: 20px;
}

.title > div:first-child {
    min-width: 292px;
    font-size: 24px;
    line-height: 31.06px;
    margin-bottom: 8px;
}
.title > div:last-child {
    font-size: 18px;
    line-height: 23.29px;
    margin-bottom: 20px;
    min-width: 270px;
    position: absolute;
}
.title b:first-child {
    font-weight: 700;
    margin-bottom: 8px;
}
.title b:last-child {
    width: 132px;
    /*display: inline-flex;*/
}
.box-package_price {
    margin-top: 20px;
    vertical-align: middle;
    width: 22%;
    min-width: 160px;
    margin-inline: 5px;
}
.price_value {
    font-weight: 700;
    font-size: 24px;
    line-height: 31.06px;
}
.price_currency {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
}
.price_value_text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
.box-package_button {
    margin-top: 26px;
    width: 20%;
    min-width: max-content;
    margin-inline: 5px;
}

.box-package_button a {
    min-width: 70px;
    display: block;
}
.box-prepayment_title {
    margin: auto;
    vertical-align: middle;
    margin-left: 20px;
    max-width: 260px;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
}
.box-prepayment_price {
    margin: auto;
    vertical-align: middle;
    width: 22%;
    min-width: 160px;
    margin-inline: 5px;
}
.box-prepayment_button {
    margin: auto;
    vertical-align: middle;
    margin-inline: 5px;
    min-width: 105px;
}

.prepayment {
    min-height: 84px;
    margin-top: 10px;
}

.responsive_content {
    display: flex;
    min-width: fit-content;
    width: 42%;
}

@media (min-width: 620px) and (max-width: 796px){
    .device-info table {
        width: 100%;
    }
    .device-info table tr td {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        display: table-cell !important;
        padding: 0 0 0 10px !important;
    }
    .device-info table tr td:first-child {
        text-align: right !important;
        color: #444 !important;
    }
    .box-products {
        font-family: "PT Sans", sans-serif;
        display: flex;
        margin: 5px auto;
        width: 100% !important;
        min-width: 590px;
        max-width: 748px;
        min-height: 84px;
        max-height: 218px;
        border: 1px solid #e6e6e6;
        background: #EEEEEE;
        border-radius: 4px;
        vertical-align: middle;
        text-align: left;
        padding-right: 10px;
    }
    .responsive_content {
        display: flex;
        min-width: max-content;
        padding: 0 0 26px 20px;
    }
    .prepayment .responsive_content {
        vertical-align: middle;
        padding: 0;
        display: flex;
    }
}
@media (max-width: 620px) {
    .box_yesterday_modal .modal-content {
        height: 570px;
    }
    .responsive_content {
        display: flex;
        padding: 0 0 7px 20px;
    }
    .device-info table {
        width: 90%;
    }
    .device-info table tr td {
        padding: 0 0 0 10px !important;
        display: table-cell !important;
    }
    .device-info table tr td:first-child {
        text-align: right !important;
        color: #444 !important;
    }
    .box-products {
        min-width: 314px;
        max-width: 748px;
        max-height: 218px;
        display: inline-block;
    }
    .prepayment {
        min-height: 120px;
    }
    .box-package {
        width: 100%;
    }
    .box-package_price {
        margin-right: 5px;
        margin-left: 0;
    }
    .prepayment .responsive_content {
        display: flex;
        min-width: fit-content;
        padding-left: 14px;
    }
    .box-prepayment_title {
        margin-top: 10px;
    }
    .title > div:last-child {
        margin-bottom: 0;
    }
}

@media (max-width: 350px) {
    .pricing-table {
        width: 100%;
    }
}

.box_yesterday_modal .modal-content {
    border-radius: 14px;
    max-width: 438px;
    max-height: 631px;
    font-family: "PT Sans", sans-serif;
}
.respect {
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0;
    text-align: center;
    margin-top: 25px;
}
.box_yesterday_modal .product-info {
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0;
    text-align: center;
    width: 318px;
    margin-top: 10px;
}
.box_yesterday_modal .product-info p:first-child {
    margin-bottom: 10px;
}
.song_count {
    display: flex;
    margin: auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    width: 155px;
    height: 55px;
    background: #EEEEEE;
    border-radius: 60px;
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 24px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 687px) {
    .box_yesterday_modal .product-info p {
        font-size: 18px !important;
    }
    .box_yesterday_modal .modal-product-image {
        margin: 25px auto;
        width: 66px;
        height: 66px;
    }
    .box_yesterday_modal .modal-product-image img {
        width: 100% !important;
    }
    .song_count {
        padding: 6px 12px;
    }
}

@media (max-height: 690px) {
    .box_yesterday_modal .modal-content {
        height: 560px !important;
    }

    .song_count {
        padding: 6px 12px;
    }
}

@media only screen and (max-device-width: 320px) {
    .box_yesterday_modal .modal-product-image {
        width: 66px;
        height: 66px;
    }
}

.ru_add_plus-info {
    position: absolute;
    margin: 142px 0 0 20px;
    display: flex;
    width: 530px;
    height: 78px;
    border: 1px solid #8B8B8B;
    box-sizing: border-box;
    border-radius: 6px;
    align-items: center;
}
.ru_add_plus-image {
    margin-left: 9px;
}
.ru_add_plus-count {
    margin-left: 10px;
    font-family: Open Sans, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

}
.ru_add_plus-count span {
    font-variant-numeric: normal;
}
.ru_add_plus-text {
    margin-left: 8px;
    margin-top: 2px;
}
.all_inclusive {
    min-height: 277px;
}
.youtube_block {
    display: flex;
    position: absolute;
    margin: 235px 0 0 20px;
}
.youtube_block-link {
    margin-left: 8px;
}
.youtube_block-link a {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    -o-transition: all 200ms;
}

.youtube_block-link a:hover {
    color: #F39D2A;
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    -o-transition: all 200ms;
}

@media (max-width: 820px) {
    .ru_add_plus-info {
        width: 65%;
    }
}

@media (max-width: 620px) {
    .all_inclusive {
        min-height: 380px;
    }
    .ru_add_plus-info {
        margin: 72px 0 0 13px;
        width: 291px;
    }
    .all_inclusive .responsive_content {
        position: absolute;
        margin: 200px 0 0 0;
    }
    .youtube_block {
        margin: 175px 0 0 20px;
    }
}

@media only screen and (min-device-width: 420px) and (max-device-width: 700px) {
    .device-info {
        margin-left: 0;
        width: 100%;
    }
}

.not_activated_box img {
    filter: opacity(50%);
    -webkit-transition: all 2s;
    -moz-transition: all 2s;
    -o-transition: all 2s;
}

.attention_activate {
    max-width: 620px;
    margin: 43px auto;
}

.attention_activate-title {
    font-size: 36px;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: 0;
}
.attention_activate-text {
    margin-top: 14px;
    font-size: 18px;
}

.attention_activate_footer p {
    margin: 18px auto;
    font-size: 18px;
    max-width: 327px;
}

.action-text {
    color:red;
}

.base_price-box {
    display: inline-flex;
    align-items: center;
    margin: 0 0 11px;
}

.base_price-old {
    color: #979797;
    text-decoration-line: line-through;
    font: 600 24px/24px 'Open Sans', sans-serif;
}

.base_price-profit {
    color: #7F21F7;
    border: thin solid #7F21F7;
    padding: 6px 10px;
    margin: 0 0 0 15px;
    font: 400 16px/20px 'Open Sans', sans-serif;
}

.old-price {
    text-decoration-line: line-through;
}

.old-price-text {
    color: #979797;
    font: 600 14px/14px 'Open Sans', sans-serif
}
.form-control {
    border-radius: 0;
    box-shadow: none;
    border-color: #d2d6de;
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.empty-list {
    border: 1px solid #e6e6e6;
    text-align: center;
    vertical-align: middle;
    padding: 20px;
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .table tr td.message-text {
        max-width: 60vw;
        white-space: break-spaces;
    }
}

.wordwrap {
    white-space: pre-wrap;      /* CSS3 */
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -pre-wrap;     /* Opera <7 */
    white-space: -o-pre-wrap;   /* Opera 7 */
    word-wrap: break-word;      /* IE */
}

.col-action {
    width: 2em;
}

.col-devices {
    width: 10em;
}

.col-enabled {
    width: 6em;
}
